import React from "react"
import { Helmet } from "react-helmet"

import CssReset from "../theme/styles/cssReset"
import Header from "../components/molocules/header"
import FooterContainer from "../components/molocules/footer"
import Alert from "../components/elements/alert/alert"
import { Link } from "gatsby"
import ChatButton from "../components/elements/chatButton"

const Layout = ({
  children,
  isSubMenuOpen,
  openAccountLink,
  forceRootMenu,
}) => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          maximum-scale="1"
        />
      </Helmet>
      <CssReset />
      <div id="main-content">
  {/*    <Alert type="Info" enabled>
           <b>Important Update: Branch Operations Due to Hurricane Milton –</b>{" "}
We are closely monitoring Hurricane Milton for any impact on our branch operations. For the safety of our customers and employees, our Jacksonville Office will close today, October 9th at 1:00pm, and will likely remain closed through Thursday.  We plan to reopen Friday, October 11th, weather permitting, but for the latest updates on branch availability, please check back here. You can always bank with us on our mobile app or at one of our ATMs.
Stay safe and thank you for your understanding!

  </Alert>

      <Alert type="Info" enabled>
       
        📢 <b>Important Update 1/22/25 - 7:00PM: Branch Operations Due to Winter Storm Enzo:</b>{" "}

Due to the lingering conditions of Winter Storm Enzo, all Georgia locations will remain closed Thursday 1/23/25. Our Jacksonville location will be open, and all transactions will process as normal. For the latest updates on branch availability, please check back here, on our social media pages, or our digital banking app. You can always bank with us on our mobile app or at one of our ATMs. Stay safe and thank you for your understanding!
*/}      <Header
        isSubMenuOpen={isSubMenuOpen}
        openAccountLink={openAccountLink}
        forceRootMenu={forceRootMenu}
      />
      {children}
      <ChatButton />
      <FooterContainer />
      </div>
    </>
  )
}

export default Layout
